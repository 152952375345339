import React from "react";
import {
  doseUtilizedData,
  noOfBeneficiariesData,
  stockAvailabilityData,
  stockOutData,
} from "../data";
import StockGraph from "./InvGraphs/LineGraph";
import ConfigureTable from "../../../../shared/configureTable/ConfigureTable";
import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import BarGraph from "./InvGraphs/BarGraph";
import { useGetOrdersGraph } from "../../../../services/shipment-services/queries";
import { useSearchParams } from "react-router-dom";
import { stringToColorHex } from "../../../../shared/utils/utils";

export default function DataOrderGraph({ handlePrint }) {
  // States
  const [view, setView] = React.useState("grid");
  const [graphType, setGraphType] = React.useState("Bars");
  const [graphHeight, setGraphHeight] = React.useState(250);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const columns = [
    { key: "sentOrders", title: "Sent Indent" },
    { key: "receivedOrders", title: "Received Indent" },
    { key: "acceptedInboundOrders", title: "Your Indent Accepted" },
    { key: "acceptedOutboundOrders", title: "Indents You Accepted" },
    { key: "cancelledInboundOrders", title: "Your Cancelled Indent" },
    { key: "cancelledOutboundOrders", title: "Others Cancelled Indent" },
    { key: "rejectedInboundOrders", title: "Your Indent Rejected" },
    { key: "rejectedOutboundOrders", title: "Indents You Rejected" },
    { key: "shippedInboundOrders", title: "Your Indent Shipped" },
    { key: "shippedOutboundOrders", title: "Indents You Shipped" },
    { key: "fulfilledInboundOrders", title: "Your Indent Fulfilled" },
    { key: "fulfilledOutboundOrders", title: "Indents You Fulfilled" },
    {
      key: "partiallyFulfilledInboundOrders",
      title: "Your Indent Partially Fulfilled",
    },
    {
      key: "partiallyFulfilledOutboundOrders",
      title: "Indents You Partially Fulfilled",
    },
    { key: "transferInboundOrders", title: "You Sent (Transfer)" },
    { key: "transferOutboundOrders", title: "You Received (Transfer)" },
  ];

  const initialColumns = {
    sentOrders: true,
    receivedOrders: true,
    acceptedInboundOrders: true,
    acceptedOutboundOrders: true,
    cancelledInboundOrders: true,
    cancelledOutboundOrders: true,
    rejectedInboundOrders: true,
    rejectedOutboundOrders: true,
    shippedInboundOrders: true,
    shippedOutboundOrders: true,
    fulfilledInboundOrders: true,
    fulfilledOutboundOrders: true,
    partiallyFulfilledInboundOrders: true,
    partiallyFulfilledOutboundOrders: true,
    transferInboundOrders: true,
    transferOutboundOrders: true,
  };

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.key],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get("locations")?.split(",") || [],
    fromDate: searchParams.get("fromDate") || null,
    toDate: searchParams.get("toDate") || null,
  };

  const {
    data: createdData,
    isLoading,
    isError,
  } = useGetOrdersGraph(apiBody, "CREATED");

  const { data: acceptedData } = useGetOrdersGraph(apiBody, "ACCEPTED");
  const { data: rejectedData } = useGetOrdersGraph(apiBody, "REJECTED");
  const { data: cancelledData } = useGetOrdersGraph(apiBody, "CANCELLED");
  const { data: shippedData } = useGetOrdersGraph(apiBody, "SHIPPED");
  const { data: fulfilledData } = useGetOrdersGraph(apiBody, "FULFILLED");
  const { data: partiallyFulfilledData } = useGetOrdersGraph(
    apiBody,
    "PARTIALLY_FULFILLED",
  );

  const { data: transferData } = useGetOrdersGraph(apiBody, null, true);

  const OrderGraphs = [
    {
      key: "sentOrders",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Sent Indents",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "receivedOrders",
      data: createdData?.inbound,
      xAxisKey: "date",
      title: "Received Indents",
      bars: Object.keys(createdData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(createdData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "acceptedInboundOrders",
      data: acceptedData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Accepted",
      bars: Object.keys(acceptedData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(acceptedData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "acceptedOutboundOrders",
      data: acceptedData?.outbound,
      xAxisKey: "date",
      title: "Indents You Accepted",
      bars: Object.keys(acceptedData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(acceptedData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "rejectedInboundOrders",
      data: rejectedData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Rejected",
      bars: Object.keys(rejectedData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(rejectedData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "rejectedOutboundOrders",
      data: rejectedData?.outbound,
      xAxisKey: "date",
      title: "Indents You Rejected",
      bars: Object.keys(rejectedData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(rejectedData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "cancelledInboundOrders",
      data: cancelledData?.inbound,
      xAxisKey: "date",
      title: "Your Cancelled Indent",
      bars: Object.keys(cancelledData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(cancelledData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "cancelledOutboundOrders",
      data: cancelledData?.outbound,
      xAxisKey: "date",
      title: "Others Cancelled Indent",
      bars: Object.keys(cancelledData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(cancelledData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "shippedInboundOrders",
      data: shippedData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Shipped",
      bars: Object.keys(shippedData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(shippedData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "shippedOutboundOrders",
      data: shippedData?.outbound,
      xAxisKey: "date",
      title: "Indents You Shipped",
      bars: Object.keys(shippedData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(shippedData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "fulfilledInboundOrders",
      data: fulfilledData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Fulfilled",
      bars: Object.keys(fulfilledData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(fulfilledData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "fulfilledOutboundOrders",
      data: fulfilledData?.outbound,
      xAxisKey: "date",
      title: "Indents You Fulfilled",
      bars: Object.keys(fulfilledData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(fulfilledData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "partiallyFulfilledInboundOrders",
      data: partiallyFulfilledData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Partially Fulfilled",
      bars: Object.keys(partiallyFulfilledData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(partiallyFulfilledData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "partiallyFulfilledOutboundOrders",
      data: partiallyFulfilledData?.outbound,
      xAxisKey: "date",
      title: "Indents You Partially Fulfilled",
      bars: Object.keys(partiallyFulfilledData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(partiallyFulfilledData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "transferInboundOrders",
      data: transferData?.inbound,
      xAxisKey: "date",
      title: "You Sent (Transfer)",
      bars: Object.keys(transferData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(transferData?.inbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: "transferOutboundOrders",
      data: transferData?.outbound,
      xAxisKey: "date",
      title: "You Received (Transfer)",
      bars: Object.keys(transferData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(transferData?.outbound?.[0] || {})
        .filter((key) => key !== "date")
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
  ];

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error: {isError.message}</div>;
  return (
    <div>
      <div className='Data_graph_header printing_graph_actions'>
        <div className='graph__left'>
          <div className='graph_column'>
            <span>Graph Filters:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              <span>Default: Show all</span>
            </button>
            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
              title={"Filter the Graph"}
            />
          </div>
          <div className='graph_column'>
            <span>Graph Exports:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handlePrint}
            >
              <span>Download as PDF</span>
            </button>
          </div>
        </div>
        {/* <h1 className='graph_title'>Indents</h1> */}
        <div className='graph__actions'>
          <div className='graph_column'>
            <span>Graph View:</span>
            <div className='graph_view_option'>
              <Tooltip title={"Full View"} arrow>
                <div
                  className={`graph_view ${view === "full" && "active"}`}
                  onClick={() => setView("full")}
                >
                  <i className='bx bxs-bar-chart-square'></i>
                </div>
              </Tooltip>
              <Tooltip title={"Grid View"} arrow>
                <div
                  className={`graph_view ${view === "grid" && "active"}`}
                  onClick={() => setView("grid")}
                >
                  <i className='bx bxs-grid-alt'></i>
                </div>
              </Tooltip>
            </div>
          </div>

          <div className='graph_column graph_type'>
            <span>Graph Type:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphType(e.target.value);
                }}
                value={graphType}
              >
                <MenuItem value={"Bars"}>Bar Graph</MenuItem>
                <MenuItem value={"Lines"}>Line Graph</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='graph_column graph_type'>
            <span>Graph Height:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphHeight(e.target.value);
                }}
                value={graphHeight}
              >
                <MenuItem value={250}>Normal</MenuItem>
                <MenuItem value={400}>Large</MenuItem>
                <MenuItem value={500}>Extra</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className={`Graph_list ${view}`}>
        {OrderGraphs.map((graph) =>
          filteredColumns.some((col) => col.key === graph.key) ? (
            graphType === "Bars" ? (
              <BarGraph
                key={graph.key}
                data={graph.data}
                xAxisKey={graph.xAxisKey}
                title={graph.title}
                bars={graph.bars}
                height={graphHeight}
              />
            ) : (
              <StockGraph
                key={graph.key}
                data={graph.data}
                xAxisKey={graph.xAxisKey}
                title={graph.title}
                lines={graph.lines}
                height={graphHeight}
              />
            )
          ) : null,
        )}
      </div>
    </div>
  );
}
