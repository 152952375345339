import React, { useRef, useEffect } from "react";
import "./ViewOrders.css";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import StatusLabel from "../../../shared/utils/statusLabel/StatusLabel";
import ProductTable from "./productTable/ProductTable";
import { useParams, useNavigate } from "react-router-dom";
import { getOrderDetails } from "../../../redux/orderActions";
import { Tooltip } from "@mui/material";
import {
  orderInStatus,
  orderOutStatus,
} from "../../../shared/common/commonFunctions";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import Printer from "../../../shared/printer/Printer";
import { useSelector } from "react-redux";

export default function ViewOrders() {
  const { userInfo } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState();
  const [statusLabel, statusColor] =
    order && order?.source?._id === userInfo?.locationId
      ? orderOutStatus(order?.status)
      : orderInStatus(order?.status);

  useEffect(() => {
    async function fetchData() {
      const res = await getOrderDetails(id);
      setOrder(res?.data);
    }
    fetchData();
  }, [id]);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const copyToClipboard = (copy) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setTooltipOpen(true);
      return navigator.clipboard.writeText(copy);
    }
    return Promise.reject(
      "This browser does not support the Clipboard API. Please use a modern browser!",
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({ contentRef: componentRef });

  return (
    <section className={`Page__main_wrapper`}>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <div className='back_Link' onClick={() => navigate(-1)}>
            <i className='bx bx-arrow-back icon_blue'></i>
          </div>
          <h1 className='Page__headline_title_fs'>{t("view_order_details")}</h1>
        </div>
        <BreadCrumb
          url1='/user/orders'
          Link1={t("order")}
          Link2={`${t("order")}: ${order?.id}`}
        />
      </div>
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          <button
            className='mi_btn mi_btn_medium mi_btn_outline'
            onClick={handlePrint}
          >
            <i className='fa-solid fa-download'></i>
            <span>{t("generate_PDF")}</span>
          </button>
        </div>
      </div>

      <div className='Order__wrapper' ref={componentRef}>
        <Printer title={"Indent Details"}>
          <div className='printing_body column_flex'>
            <div className='Order__summary_space'>
              <div className='Order__summary_card'>
                <div className='Order__summary_header'>
                  <div className='Order__flex'>
                    <h1 className='page__body_heading_fs'>
                      {t("order_id")} - {order?.id}
                    </h1>
                    <Tooltip
                      title='Copied'
                      open={tooltipOpen}
                      leaveDelay={1000}
                      onClose={() => setTooltipOpen(false)}
                    >
                      <div
                        className='copy_icon mi_link'
                        onClick={() => copyToClipboard(order?.id)}
                      >
                        <i className='fa-regular fa-copy'></i>
                      </div>
                    </Tooltip>
                  </div>
                  <StatusLabel status={statusLabel} tone={statusColor} />
                  {/* {order?.status === "CREATED" ? (
              <StatusLabel status='Received' tone='blue' />
            ) : order?.status === "ACCEPTED" ? (
              <StatusLabel status={order?.status} tone='green' />
            ) : order?.status === "SHIPPED" ? (
              <StatusLabel status={order?.status} tone='red' />
            ) : order?.status === "PARTIALLY_SHIPPED" ? (
              <StatusLabel status={order?.status} tone='red' />
            ) : order?.status === "PARTIALLY_FULFILLED" ? (
              <StatusLabel status={order?.status} tone='pink' />
            ) : order?.status === "FULFILLED" ? (
              <StatusLabel status={order?.status} tone='green' />
            ) : order?.status === "CANCELLED" ? (
              <StatusLabel status={order?.status} tone='red' />
            ) : order?.status === "REJECTED" ? (
              <StatusLabel status={order?.status} tone='red' />
            ) : (
              <StatusLabel status={order?.status} tone='blue' />
            )} */}
                </div>
                <div className='Order__summary_body'>
                  <article className='Order__summary_body_list'>
                    <div className='summary_body_content_card'>
                      <h1 className='page__body_fs mi_bold'>
                        {t("order_created_by")}
                      </h1>
                      <h1 className='page__notion'>:</h1>
                      <p className='page__body_fs '>
                        {order?.createdBy?.user?.firstName +
                          " " +
                          order?.createdBy?.user?.lastName}
                      </p>
                    </div>
                    <div className='summary_body_content_card'>
                      <h1 className='page__body_fs mi_bold'>
                        {t("order_date")}
                      </h1>
                      <h1 className='page__notion'>:</h1>
                      <p className='page__body_fs '>
                        {new Date(order?.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                  </article>
                  {order?.updatedBy?.length > 0 && (
                    <article className='Order__summary_body_actions'>
                      <div className='summary_body_content_card'>
                        <h1 className='page__body_fs mi_bold'>
                          {t("last_updated_by")}
                        </h1>
                        <h1 className='page__notion'>:</h1>
                        <div className='order__updated_list'>
                          <div className='updated_space'>
                            <p className='page__body_fs'>
                              {order?.updatedBy?.[0]?.user?.firstName +
                                " " +
                                order?.updatedBy?.[0]?.user?.lastName}
                              ,
                            </p>
                            <p className='page__body_fs '>
                              at {order?.updatedBy?.[0]?.location?.name},
                            </p>
                            <p className='page__body_fs '>
                              {new Date(
                                order?.updatedBy?.[0]?.timestamp,
                              ).toLocaleString()}
                              ,
                            </p>
                            <p className='page__body_fs '>{order?.status}</p>
                          </div>

                          <div className='updated_space'>
                            <p className='page__body_fs'>
                              {order?.updatedBy?.[0]?.user?.firstName +
                                " " +
                                order?.updatedBy?.[0]?.user?.lastName}
                              ,
                            </p>
                            <p className='page__body_fs '>
                              at {order?.updatedBy?.[0]?.location?.name},
                            </p>
                            <p className='page__body_fs '>
                              {new Date(
                                order?.updatedBy?.[0]?.timestamp,
                              ).toLocaleString()}
                              ,
                            </p>
                            <p className='page__body_fs '>{order?.status}</p>
                          </div>

                          {/* <div className="updated_space">
                      <p className="page__body_fs ">User 2</p>
                      <p className="page__body_fs ">00:00</p>
                      <p className="page__body_fs ">Monday</p>
                    </div> */}
                        </div>
                      </div>
                    </article>
                  )}
                </div>
              </div>
              <div className='Order__address_space'>
                <div className='Order__address_card'>
                  <div className='Order__address_body'>
                    <article className='address_card_space supplier__address'>
                      <div className='Order__flex'>
                        <i className='fa-solid fa-building'></i>
                        <h1 className='page__body_heading_fs'>
                          {t("supplier_details")}{" "}
                        </h1>
                      </div>
                      <p className='page__body_fs '>
                        {order?.destination?.name +
                          ", " +
                          order?.destination?.division +
                          ", " +
                          order?.destination?.district +
                          ", " +
                          order?.destination?.country}
                      </p>
                    </article>
                    <article className='address_card_space delivery__address'>
                      <div className='Order__flex'>
                        <i className='fa-solid fa-truck-arrow-right'></i>
                        <h1 className='page__body_heading_fs'>
                          {" "}
                          {t("delivery_details")}
                        </h1>
                      </div>
                      <p className='page__body_fs '>
                        {order?.source?.name +
                          ", " +
                          order?.source?.division +
                          ", " +
                          order?.source?.district +
                          ", " +
                          order?.source?.country}
                      </p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <div className='Order__product_details'>
              <ProductTable products={order?.products} />
            </div>
          </div>
        </Printer>
      </div>
    </section>
  );
}
