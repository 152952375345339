import React from "react";
import {
  stockAvailabilityData,
  stockOutData,
  expiredStockData,
  nearingExpiryStockData,
  quarantineStockData,
  disposedStockData,
  rejectedStockData,
  wastedStockData,
  netUtilizationData,
} from "../data";
import StockGraph from "./InvGraphs/LineGraph";
import ConfigureTable from "../../../../shared/configureTable/ConfigureTable";
import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import LineGraph from "./InvGraphs/LineGraph";
import { useSearchParams } from "react-router-dom";
import { useGetOrdersGraph } from "../../../../services/shipment-services/queries";
import { stringToColorHex } from "../../../../shared/utils/utils";
import BarGraph from "./InvGraphs/BarGraph";

function DataInvGraph({ handlePrint }) {
  // States
  const [view, setView] = React.useState("grid");
  const [graphType, setGraphType] = React.useState("Bars");
  const [graphHeight, setGraphHeight] = React.useState(250);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const columns = [
    { key: "stockAvailable", title: "Stock Availability" },
    { key: "stockOutCount", title: "Stock Out Count" },
    { key: "expiredStock", title: "Expired Stock" },
    { key: "nearExpiryStock", title: "Stock Nearing Expiry" },
    { key: "quarantineStock", title: "Quarantine Stock" },
    { key: "disposedStock", title: "Disposed Stock" },
    { key: "rejectedStock", title: "Rejected Stock" },
    { key: "wastedStock", title: "Wasted Stock" },
    { key: "netUtilization", title: "Net Utilization" },
  ];

  const initialColumns = {
    stockAvailable: true,
    stockOutCount: true,
    expiredStock: true,
    nearExpiryStock: true,
    quarantineStock: true,
    disposedStock: true,
    rejectedStock: true,
    wastedStock: true,
    netUtilization: true,
  };

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.key],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get("locations")?.split(",") || [],
  };

  const {
    data: createdData,
    isLoading,
    isError,
  } = useGetOrdersGraph(apiBody, "CREATED");

  const InventoryGraphs = [
    {
      key: "stockAvailable",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Stock Availability",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
    {
      key: "stockOutCount",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Stock Out Count",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
    {
      key: "expiredStock",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Expired Stock",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
    {
      key: "nearExpiryStock",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Stock Nearing Expiry",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
    {
      key: "quarantineStock",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Quarantine Stock",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
    {
      key: "disposedStock",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Disposed Stock",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
    {
      key: "rejectedStock",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Rejected Stock",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
    {
      key: "wastedStock",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Wasted Stock",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
    {
      key: "netUtilization",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Net Utilization",
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          fill: stringToColorHex(dataKey),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((dataKey) => dataKey !== "date")
        .map((dataKey) => ({
          dataKey,
          stroke: stringToColorHex(dataKey),
        })),
    },
  ];

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error: {isError.message}</div>;
  return (
    <div>
      <div className='Data_graph_header printing_graph_actions'>
        <div className='graph__left'>
          <div className='graph_column'>
            <span>Graph Filters:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              <span>Default: Show all</span>
            </button>
            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
              title={"Filter the Graph"}
            />
          </div>
          <div className='graph_column'>
            <span>Graph Exports:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handlePrint}
            >
              <span>Download as PDF</span>
            </button>
          </div>
        </div>
        {/* <h1 className='graph_title'>Indents</h1> */}
        <div className='graph__actions'>
          <div className='graph_column'>
            <span>Graph View:</span>
            <div className='graph_view_option'>
              <Tooltip title={"Full View"} arrow>
                <div
                  className={`graph_view ${view === "full" && "active"}`}
                  onClick={() => setView("full")}
                >
                  <i className='bx bxs-bar-chart-square'></i>
                </div>
              </Tooltip>
              <Tooltip title={"Grid View"} arrow>
                <div
                  className={`graph_view ${view === "grid" && "active"}`}
                  onClick={() => setView("grid")}
                >
                  <i className='bx bxs-grid-alt'></i>
                </div>
              </Tooltip>
            </div>
          </div>

          <div className='graph_column graph_type'>
            <span>Graph Type:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphType(e.target.value);
                }}
                value={graphType}
              >
                <MenuItem value={"Bars"}>Bar Graph</MenuItem>
                <MenuItem value={"Lines"}>Line Graph</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='graph_column graph_type'>
            <span>Graph Height:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphHeight(e.target.value);
                }}
                value={graphHeight}
              >
                <MenuItem value={250}>Normal</MenuItem>
                <MenuItem value={400}>Large</MenuItem>
                <MenuItem value={500}>Extra</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className={`Graph_list ${view}`}>
        {InventoryGraphs.map((graph) =>
          filteredColumns.some((col) => col.key === graph.key) ? (
            graphType === "Bars" ? (
              <BarGraph
                key={graph.key}
                data={graph.data}
                xAxisKey={graph.xAxisKey}
                title={graph.title}
                bars={graph.bars}
                height={graphHeight}
              />
            ) : (
              <StockGraph
                key={graph.key}
                data={graph.data}
                xAxisKey={graph.xAxisKey}
                title={graph.title}
                lines={graph.lines}
                height={graphHeight}
              />
            )
          ) : null,
        )}
      </div>
    </div>
  );
}

export default DataInvGraph;
