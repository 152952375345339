import React from 'react';
import {
  BarChart,
  CartesianGrid,
  YAxis,
  XAxis,
  LineChart,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
} from 'recharts';

// Reusable component definition
export default function LineGraph({ data, xAxisKey, lines, title, height }) {
  return (
    <div className='chart_container'>
      <div className='chart_headline'>
        <h1 className='page__body_fs mi_bold'>{title}</h1>
      </div>
      <ResponsiveContainer width='100%' height={height}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey={xAxisKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          {lines.map((line, index) => (
            <Line
              key={index}
              type='monotone'
              dataKey={line.dataKey}
              stroke={line.stroke}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
