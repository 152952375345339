import React, { useEffect, useState } from 'react';
import AnalyticCard from '../../../shared/utils/analyticCard/AnalyticCard';
import IconSummary from '/icons/analytics/IconSummary.svg';
import IconCurrentStock from '/icons/analytics/IconCurrentStock.svg';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../shared/common/commonFunctions';
import BeneficiarySummary from './beneficiaryTables/BeneficiarySummary';
import TotalBeneficiaries from './beneficiaryTables/TotalBeneficiaries';

import Export from '/icons/all/Export.svg';
import Filter from '/icons/all/Filter.svg';
import Import from '/icons/all/Import.svg';
import LastMile from '/icons/all/Last Mile.svg';

import {
  getBeneficiaries,
  getBeneficiariesAnalytics,
  getUtilized,
} from '../../../redux/inventoryActions';
import { useTranslation } from 'react-i18next';

import { useRef, useCallback } from 'react';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import ConfigureTable from '../../../shared/configureTable/ConfigureTable';
import UtilizedExportTable from './LastmileExports/UtilizedExportTable';
import BeneficiariesExportTable from './LastmileExports/BeneficiariesExportTable';
import DataShadowExport from '../../../shared/dataExport/DataShadowExport';

export default function Beneficiaries() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('summary');
  const [beneficiariesList, setBeneficiariesList] = useState({});
  const [beneficiariesAnalytics, setBeneficiariesAnalytics] = useState();
  const currentDate = new Date().toISOString();
  const isToday = currentTab === 'vaccinatedToday' ? currentDate : null;
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getBeneficiaries(isToday, page, limit);
      setBeneficiariesList(result?.data);
      const res = await getBeneficiariesAnalytics();
      setBeneficiariesAnalytics(res?.data);
    };
    fetchData();
  }, [currentTab, page, limit]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnData, setColumnData] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openPrint, setOpenPrint] = useState(false);
  const [printData, setShipmentsPrint] = useState();
  const [tab, setTab] = useState();

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  const openPrintPopup = async () => {
    if (currentTab === 'summary') {
      const res = await getUtilized(0, 0);
      if (res?.success === true) {
        const data = res?.data?.data?.map((row) => ({
          vaccine: row.productDetails.name,
          batch_no: row.batchNo,
          dose_utilized: row?.quantityUtilized,
          status: row.status !== 'OPEN' ? 'completed' : 'Open',
          last_updated: new Date(row?.updatedAt).toLocaleDateString(),
        }));
        setShipmentsPrint(data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'vaccinatedSoFar') {
      const result = await getBeneficiaries(false, 0, 0);
      if (result?.success === true) {
        const data = result?.data?.data?.map((row) => ({
          vaccine: row.productDetails.name,
          batch_no: row.batchNo,
          dose_number: row.doseNo,
          beneficiary_id: 'ID:' + row.externalId,
          vaccinated_date: new Date(row.createdAt).toLocaleDateString(),
        }));
        setShipmentsPrint(data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'vaccinatedToday') {
      const result = await getBeneficiaries(true, 0, 0);
      if (result?.success === true) {
        const data = result?.data?.data?.map((row) => ({
          vaccine: row.productDetails.name,
          batch_no: row.batchNo,
          dose_number: row.doseNo,
          beneficiary_id: 'ID:' + row.externalId,
          vaccinated_date: new Date(row.createdAt).toLocaleDateString(),
        }));
        setShipmentsPrint(data);
        setOpenPrint(true);
      }
    }
  };

  const open = Boolean(anchorEl);

  let initialColumns = {};
  let columns = [];

  const summaryColumns = [
    { key: 'vaccine', title: 'vaccine' },
    { key: 'batch_no', title: 'batch_no' },
    { key: 'dose_utilized', title: 'dose_utilized' },
    { key: 'status', title: 'status' },
    { key: 'last_updated', title: 'last_updated' },
    { key: 'action', title: 'action' },
  ];

  const summaryInitialColumns = {
    vaccine: true,
    batch_no: true,
    dose_utilized: true,
    status: true,
    last_updated: true,
    action: true,
  };

  const totalBenefsColumns = [
    { key: 'vaccine', title: 'vaccine' },
    { key: 'batch_no', title: 'batch_no' },
    { key: 'dose_number', title: 'dose_number' },
    { key: 'beneficiary_id', title: 'beneficiary_id' },
    { key: 'vaccinated_date', title: 'vaccinated_date' },
  ];

  const totalBenefsInitialColumns = {
    vaccine: true,
    batch_no: true,
    dose_number: true,
    beneficiary_id: true,
    vaccinated_date: true,
  };

  if (currentTab === 'summary') {
    initialColumns = summaryInitialColumns;
    columns = summaryColumns;
  } else if (currentTab === 'vaccinatedSoFar') {
    initialColumns = totalBenefsInitialColumns;
    columns = totalBenefsColumns;
  } else if (currentTab === 'vaccinatedToday') {
    initialColumns = totalBenefsInitialColumns;
    columns = totalBenefsColumns;
  }

  const [selectedColumns, setSelectedColumns] = useState(initialColumns);
  const [disableFilter, setDisableFilter] = useState(true);

  useEffect(() => {
    setSelectedColumns(initialColumns);
  }, [currentTab]);

  function areAllTrue(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== true) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    const result = areAllTrue(selectedColumns);
    setDisableFilter(result);
  }, [selectedColumns]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns?.filter(
    (column) => selectedColumns[column.key]
  );

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(
        wb,
        `Beneficiary_${currentTab}_${dayjs().format('DD-MM-YYYY')}.xlsx`
      );
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, [currentTab]);

  return (
    <section className='Page__main_wrapper'>
      {/* <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img src={LastMile} className='Lastmile__icon' />
          <h1 className='Page__headline_title_fs'>{t('last_mile')}</h1>
        </div>
      </div> */}
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          <Link
            to='/user/beneficiary/add-beneficiary'
            className='mi_btn mi_btn_medium mi_btn_primary'
          >
            <i className='fa-solid fa-plus'></i>
            <span>{t('add_beneficiary')}</span>
          </Link>
        </div>
        <div className='Page__right_actions'>
          {/* <article className='mi_search_bar'>
            <i className='fa-solid fa-magnifying-glass'></i>
            <input
              type='text'
              placeholder='Search by Product'
              onInput={(e) => {
                SearchList(e.target.value);
              }}
            />
          </article> */}
          <button
            className='mi_btn mi_btn_medium mi_btn_outline'
            onClick={handleClick}
          >
            {/* <i className='fa-solid fa-sliders'></i> */}
            <img src={Filter} style={{ height: '18px' }} />
            <span>{t('filter')}</span>
          </button>

          <ConfigureTable
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            value={columnData}
            setValue={setColumnData}
            columns={columns}
            selectedColumns={selectedColumns}
            onColumnToggle={handleColumnToggle}
            handleResetColumns={handleResetColumns}
            filters={disableFilter}
          />

          <button
            onClick={openPrintPopup}
            className='mi_btn mi_btn_medium mi_btn_outline'
          >
            <img src={Export} style={{ height: '18px' }} />
            <span>{t('export')}</span>
          </button>
        </div>
      </div>
      <div className='Page__Analytic_list'>
        <AnalyticCard
          state='summary'
          icon={IconCurrentStock}
          title={t('total_no_of_units_utilized')}
          number={formatNumber(beneficiariesAnalytics?.totalQuantityUtilized)}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticCard
          state='vaccinatedSoFar'
          icon={IconSummary}
          title={t('no_of_benefs_vaccinated_so_far')}
          number={formatNumber(beneficiariesAnalytics?.totalBeneficiaries)}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticCard
          state='vaccinatedToday'
          icon={IconSummary}
          title={t('no_of_benefs_vaccinated_today')}
          number={formatNumber(beneficiariesAnalytics?.todayBeneficiaries)}
          tab={currentTab}
          setTab={setCurrentTab}
        />
      </div>

      <div className='Page__table_space'>
        {currentTab === 'summary' && (
          <BeneficiarySummary
            t={t}
            tableRef={tableRef}
            filteredColumns={filteredColumns}
          />
        )}

        {currentTab === 'vaccinatedSoFar' && (
          <TotalBeneficiaries
            beneficiariesList={beneficiariesList}
            filteredColumns={filteredColumns}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            t={t}
            tableRef={tableRef}
          />
        )}
        {currentTab === 'vaccinatedToday' && (
          <TotalBeneficiaries
            beneficiariesList={beneficiariesList}
            filteredColumns={filteredColumns}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            currentTab={currentTab}
            t={t}
            tableRef={tableRef}
          />
        )}
      </div>
      <DataShadowExport
        open={openPrint}
        onClose={handleClosePrintPopup}
        tableComponent={
          <>
            {currentTab === 'summary' ? (
              <UtilizedExportTable
                filteredColumns={filteredColumns}
                tableRef={tableRef}
                printData={printData}
              />
            ) : (
              <BeneficiariesExportTable
                filteredColumns={filteredColumns}
                tableRef={tableRef}
                printData={printData}
              />
            )}
          </>
        }
        title='Last Mile'
        printData={printData}
        currentTab={currentTab}
        xport={xport}
      />
    </section>
  );
}
