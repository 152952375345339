import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import ReviewProducts from "../../components/reviewProducts/ReviewProducts";
import { addInventory } from "../../../redux/inventoryActions";
import { callPopup } from "../../../store/slices/popupSlice";
import InventoryIcon from "/icons/all/inventory/Current Stock.svg";
import "./ReviewInventory.css";

export default function ReviewInventory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [products, setProducts] = useState(location.state?.products || []);
  const [inventoryCompleted, setInventoryCompleted] = useState(false);

  const removeProduct = (id, batchNo, mfgDate, expDate) => {
    setProducts((currentProducts) =>
      currentProducts.filter(
        (item) =>
          item.productId !== id ||
          item.batchNo !== batchNo ||
          item.mfgDate !== mfgDate ||
          item.expDate !== expDate,
      ),
    );
  };

  const goBack = () => {
    navigate("/user/inventory/add-inventory", {
      state: { products },
    });
  };

  const addInv = async () => {
    try {
      const res = await addInventory({ products });
      const popupType = res.success ? "success" : "error";
      const message = res.success ? t("inventory_updated_msg") : res.data.data;
      dispatch(
        callPopup({
          open: true,
          type: popupType,
          page: "inventory",
          message,
          action: res.success ? "/user/inventory" : undefined,
        }),
      );

      if (res.success) setInventoryCompleted(true);
    } catch (error) {
      console.error("Failed to add inventory", error);
    }
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img
            src={InventoryIcon}
            className='Campaign__icon'
            alt='Inventory Icon'
          />
          <h1 className='Page__headline_title_fs'>{t("review_inventory")}</h1>
        </div>
        <BreadCrumb
          url1='/user/inventory'
          Link1={t("inventory")}
          Link2={t("add_inventory")}
          Link3={t("review_inventory")}
        />
      </div>
      <div className='Page__select_tab_wrapper'>
        <div className='Page__select_tab_header'>
          <div className='Select__tab_headline'>
            <h1 className='page__body_heading_fs'>{t("product_list")}</h1>
          </div>
        </div>
        <div className='Review__product_list'>
          {products.map((row, i) => (
            <ReviewProducts
              key={i}
              row={row}
              type='inventory'
              removeProductInventory={removeProduct}
              t={t}
            />
          ))}
        </div>
      </div>
      <section className='InventoryOrders__actions'>
        {inventoryCompleted ? (
          <button
            onClick={() => navigate("/user/inventory")}
            className='mi_btn mi_btn_medium mi_btn_primary'
          >
            <span>{t("go_to_inventory")}</span>
          </button>
        ) : (
          <div className='Page__left_actions'>
            <button
              onClick={goBack}
              className='mi_btn mi_btn_medium mi_btn_outline'
            >
              <span>{t("back")}</span>
            </button>
            <button
              onClick={addInv}
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              <span>{t("confirm")}</span>
            </button>
          </div>
        )}
      </section>
    </section>
  );
}
