import React from 'react';

import StockGraph from './InvGraphs/LineGraph';
import ConfigureTable from '../../../../shared/configureTable/ConfigureTable';
import { Tooltip } from '@mui/material';
import BarGraph from './InvGraphs/BarGraph';
import { useSearchParams } from 'react-router-dom';
import { useGetShipmentsGraph } from '../../../../services/shipment-services/queries';
import { stringToColorHex } from '../../../../shared/utils/utils';
import { FormControl, MenuItem, Select } from '@mui/material';

export default function DataShipmentGraph({handlePrint}) {
  // States
  const [view, setView] = React.useState('grid');
  const [graphType, setGraphType] = React.useState('Bars');
  const [graphHeight, setGraphHeight] = React.useState(250);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const columns = [
    { key: 'sentSupply', title: 'Sent Supply' },
    { key: 'receivedSupply', title: 'Received Supply' },
    { key: 'inboundReceived', title: 'Received Supply' },
    { key: 'outboundReceived', title: 'Received Supply' },
    { key: 'inboundPartiallyReceived', title: 'PartiallyReceived Supply' },
    { key: 'outboundPartiallyReceived', title: 'PartiallyReceived Supply' },
  ];

  const initialColumns = {
    sentSupply: true,
    receivedSupply: true,
    inboundReceived: true,
    outboundReceived: true,
    inboundPartiallyReceived: true,
    outboundPartiallyReceived: true,
  };

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.key]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get('locations').split(','),
    fromDate: searchParams.get('fromDate') || null,
    toDate: searchParams.get('toDate') || null,
  };

  const {
    data: createdData,
    isLoading,
    isError,
  } = useGetShipmentsGraph(apiBody, 'CREATED');

  const { data: receivedData } = useGetShipmentsGraph(apiBody, 'RECEIVED');
  const { data: partiallyReceivedData } = useGetShipmentsGraph(
    apiBody,
    'PARTIALLY_RECEIVED'
  );

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error: {isError.message}</div>;

  const SupplyGraphs = [
    {
      key: 'receivedSupply',
      data: createdData?.inbound,
      xAxisKey: 'date',
      title: 'Shipped No. of.Shipments by any given date (You sent)',
      bars: Object.keys(createdData?.inbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(createdData?.inbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: 'sentSupply',
      data: createdData?.outbound,
      xAxisKey: 'date',
      title: 'Shipped No. of.Shipments by any given date (Others sent)',
      bars: Object.keys(createdData?.outbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(createdData?.outbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: 'inboundReceived',
      data: receivedData?.inbound,
      xAxisKey: 'date',
      title: 'Delivered No. of Supply by any given date',
      bars: Object.keys(receivedData?.inbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(receivedData?.inbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: 'outboundReceived',
      data: receivedData?.outbound,
      xAxisKey: 'date',
      title: 'Received No.of.Supply by any given date',
      bars: Object.keys(receivedData?.outbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(receivedData?.outbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: 'inboundPartiallyReceived',
      data: partiallyReceivedData?.inbound,
      xAxisKey: 'date',
      title: 'Partially Delivered No .of.Supply by any given date',
      bars: Object.keys(partiallyReceivedData?.inbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(partiallyReceivedData?.inbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
    {
      key: 'outboundPartiallyReceived',
      data: partiallyReceivedData?.outbound,
      xAxisKey: 'date',
      title: 'Partially Received No Of Supply By any given date',
      bars: Object.keys(partiallyReceivedData?.outbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          fill: stringToColorHex(key),
        })),
      lines: Object.keys(partiallyReceivedData?.outbound?.[0] || {})
        .filter((key) => key !== 'date')
        .map((key) => ({
          dataKey: key,
          stroke: stringToColorHex(key),
        })),
    },
  ];

  return (
    <div>
      <div className='Data_graph_header printing_graph_actions'>
        <div className='graph__left'>
          <div className='graph_column'>
            <span>Graph Filters:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              <span>Default: Show all</span>
            </button>
            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
              title={'Filter the Graph'}
            />
          </div>
          <div className='graph_column'>
            <span>Graph Exports:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handlePrint}
            >
              <span>Download as PDF</span>
            </button>
          </div>
        </div>
        {/* <h1 className='graph_title'>Indents</h1> */}
        <div className='graph__actions'>
          <div className='graph_column'>
            <span>Graph View:</span>
            <div className='graph_view_option'>
              <Tooltip title={'Full View'} arrow>
                <div
                  className={`graph_view ${view === 'full' && 'active'}`}
                  onClick={() => setView('full')}
                >
                  <i className='bx bxs-bar-chart-square'></i>
                </div>
              </Tooltip>
              <Tooltip title={'Grid View'} arrow>
                <div
                  className={`graph_view ${view === 'grid' && 'active'}`}
                  onClick={() => setView('grid')}
                >
                  <i className='bx bxs-grid-alt'></i>
                </div>
              </Tooltip>
            </div>
          </div>

          <div className='graph_column graph_type'>
            <span>Graph Type:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphType(e.target.value);
                }}
                value={graphType}
              >
                <MenuItem value={'Bars'}>Bar Graph</MenuItem>
                <MenuItem value={'Lines'}>Line Graph</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='graph_column graph_type'>
            <span>Graph Height:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphHeight(e.target.value);
                }}
                value={graphHeight}
              >
                <MenuItem value={250}>Normal</MenuItem>
                <MenuItem value={400}>Large</MenuItem>
                <MenuItem value={500}>Extra</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className={`Graph_list ${view}`}>
        {SupplyGraphs.map((graph) =>
          filteredColumns.some((col) => col.key === graph.key) ? (
            graphType === 'Bars' ? (
              <BarGraph
                key={graph.key}
                data={graph.data}
                xAxisKey={graph.xAxisKey}
                title={graph.title}
                bars={graph.bars}
                height={graphHeight}
              />
            ) : (
              <StockGraph
                key={graph.key}
                data={graph.data}
                xAxisKey={graph.xAxisKey}
                title={graph.title}
                lines={graph.lines}
                height={graphHeight}
              />
            )
          ) : null
        )}
      </div>
    </div>
  );
}
