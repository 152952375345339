import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import { useTranslation } from 'react-i18next';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: '#fff',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid #eaecef`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid #e1e4e8`,
  boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: '#24292e',
  backgroundColor: '#fff',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid #eaecef`,
  '& input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid #eaecef`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 1px rgba(3, 102, 214, 0.2)`,
      borderColor: '#208196',
    },
  },
}));

export default function ConfigureTable({
  open,
  anchorEl,
  handleClose,
  columns,
  selectedColumns,
  onColumnToggle,
  handleResetColumns,
  filters,
  title,
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <StyledPopper open={open} anchorEl={anchorEl} placement='bottom-start'>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <div className='Filter__header'>
              <p className='filter__heading_fs'>
                {title ? title : t('configure_table')}
              </p>
              <button
                className={
                  filters
                    ? 'mi_btn mi_btn_click_disabled'
                    : 'mi_btn mi_btn_click'
                }
                onClick={handleResetColumns}
                disabled={filters}
              >
                <span>{t('reset')}</span>
              </button>
            </div>
            <Autocomplete
              open
              multiple
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={columns.filter((column) => selectedColumns[column.key])}
              onChange={(_, newValue) => {
                const updatedColumns = { ...selectedColumns };

                Object.keys(updatedColumns).forEach((key) => {
                  updatedColumns[key] = false;
                });

                newValue.forEach((column) => {
                  updatedColumns[column.key] = true;
                });

                onColumnToggle(updatedColumns);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText='No Column'
              options={columns}
              getOptionLabel={(option) => option.key}
              renderOption={(props, option, { selected }) => (
                <li {...props} onClick={() => onColumnToggle(option.key)}>
                  <div className='Filter__options'>
                    <input
                      type='checkbox'
                      checked={selected}
                      onChange={() => {}}
                    />
                    <p className='page__note_fs text_caps'>{t(option.title)}</p>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={t('search_column')}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
