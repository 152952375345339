import axios from 'axios';
import { API_URL } from '../../config';
import { buildParams, buildParamsWithPagination } from '../../utils/helper';

// Inbound Suppy Data Visualization
export const getInboundSupplyData = async (data, page, limit) => {
  const params = buildParams(data, page, limit);
  return (
    await axios.get(`${API_URL.supplyUrlv2}/inbound`, {
      params,
    })
  ).data.data;
};

// Outbound Suppy Data Visualization
export const getOutboundSupplyData = async (data, page, limit) => {
  const params = buildParams(data, page, limit);
  return (
    await axios.get(`${API_URL.supplyUrlv2}/outbound`, {
      params,
    })
  ).data.data;
};

// Inbound Indent Data Visualization
export const getInboundIndentData = async (data, page, limit) => {
  const params = buildParams(data, page, limit);
  return (
    await axios.get(`${API_URL.indentUrlv2}/inbound`, {
      params,
    })
  ).data.data;
};

// Outbound Indent Data Visualization
export const getOutboundIndentData = async (data, page, limit) => {
  const params = buildParams(data, page, limit);
  return (
    await axios.get(`${API_URL.indentUrlv2}/outbound`, {
      params,
    })
  ).data.data;
};

// Order Graph Visualization
export const getOrderGraph = async (data, status, isTransfer) => {
  const params = buildParamsWithPagination(data, status, isTransfer);
  return (
    await axios.get(`${API_URL.createOrderUrl}/graph`, {
      params,
    })
  ).data.data;
};

// Shipments Graph Visualization
export const getShipmentGraph = async (data, status) => {
  const params = buildParamsWithPagination(data, status);
  return (
    await axios.get(`${API_URL.createShipmentUrl}/graph`, {
      params,
    })
  ).data.data;
};
