import React from 'react';
import ConfigureTable from '../../../../shared/configureTable/ConfigureTable';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import { useGetAssetsGraph } from '../../../../services/coldchain-services/queries';
import { useSearchParams } from 'react-router-dom';
import PieGraph from './InvGraphs/PieChart';

export default function DataColdChainGraph({ handlePrint }) {
  // States
  const [view, setView] = React.useState('grid');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [graphType, setGraphType] = React.useState('Bars');
  const [graphHeight, setGraphHeight] = React.useState(250);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // Columns configuration based on provided data
  const columns = [
    { key: 'yearOfInstallation', title: 'Year of Installation' },
    { key: 'model', title: 'Model' },
    { key: 'type', title: 'Type' },
    { key: 'manufacturer', title: 'Manufacturer' },
    { key: 'powerSource', title: 'Power Source' },
    { key: 'pqsStatus', title: 'PQS Status' },
    { key: 'status', title: 'Status' },
    { key: 'capacity', title: 'Capacity' },
  ];

  const initialColumns = columns.reduce((acc, column) => {
    acc[column.key] = true;
    return acc;
  }, {});

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const pieChartDataMap = {
    yearOfInstallation: 'Year of Installation',
    model: 'Model',
    type: 'Type',
    manufacturer: 'Manufacturer',
    powerSource: 'Power Source',
    pqsStatus: 'PQS Status',
    status: 'Status',
    capacity: 'Capacity',
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get('locations').split(','),
  };

  const { data, isLoading, isError } = useGetAssetsGraph(apiBody);

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error: {isError.message}</div>;

  return (
    <div>
      <div className='Data_graph_header printing_graph_actions'>
        <div className='graph__left'>
          <div className='graph_column'>
            <span>Graph Filters:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              <span>Default: Show all</span>
            </button>
            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
              title={'Filter the Graph'}
            />
          </div>
          <div className='graph_column'>
            <span>Graph Exports:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handlePrint}
            >
              <span>Download as PDF</span>
            </button>
          </div>
        </div>
        {/* <h1 className='graph_title'>Indents</h1> */}
        <div className='graph__actions'>
          <div className='graph_column'>
            <span>Graph View:</span>
            <div className='graph_view_option'>
              <Tooltip title={'Full View'} arrow>
                <div
                  className={`graph_view ${view === 'full' && 'active'}`}
                  onClick={() => setView('full')}
                >
                  <i className='bx bxs-bar-chart-square'></i>
                </div>
              </Tooltip>
              <Tooltip title={'Grid View'} arrow>
                <div
                  className={`graph_view ${view === 'grid' && 'active'}`}
                  onClick={() => setView('grid')}
                >
                  <i className='bx bxs-grid-alt'></i>
                </div>
              </Tooltip>
            </div>
          </div>

      
          <div className='graph_column graph_type'>
            <span>Graph Height:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphHeight(e.target.value);
                }}
                value={graphHeight}
              >
                <MenuItem value={250}>Normal</MenuItem>
                <MenuItem value={400}>Large</MenuItem>
                <MenuItem value={500}>Extra</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className={`Graph_list ${view}`}>
        {Object.keys(pieChartDataMap).map(
          (key) =>
            selectedColumns[key] && (
              <PieGraph
                key={key}
                data={data[key]}
                title={pieChartDataMap[key]}
                height={graphHeight}
              />
            )
        )}
      </div>
    </div>
  );
}
