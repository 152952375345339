import React, { useState, useEffect, useRef } from "react";
import AnalyticCard from "../../../../shared/utils/analyticCard/AnalyticCard";
import OrderOutTable from "./orderTables/OrderOutTable";
import OrdersSent from "/icons/all/orders/Orders Sent.svg";
import OrdersAccepted from "/icons/all/orders/Orders Accepted.svg";
import OrdersRejected from "/icons/all/orders/Orders Rejected.svg";
import OrdersFulfilled from "/icons/all/orders/Order fullfilled.svg";
import PartiallyFulfilled from "/icons/all/orders/Partially fullfilled order.svg";
import OrdersCanceled from "/icons/all/orders/Cancelled order.svg";
import PartiallyShipped from "/icons/all/orders/Partially shipped order.svg";
import OrdersTransfers from "/icons/all/orders/Order Transfers.svg";
import Shipped from "/icons/all/orders/Shipped.svg";
import Summary from "/icons/all/orders/summary.svg";
import {
  getOutboundOrders,
  getOutboundOrdersAnalytics,
} from "../../../../redux/orderActions";
import { formatNumber } from "../../../../shared/common/commonFunctions";
import Fuse from "fuse.js";
import { useTranslation } from "react-i18next";

export default function OutboundOrders({
  searchCapture,
  filteredColumns,
  orgLevel,
  setTab,
  setTransfer,
}) {
  const { t } = useTranslation();
  const [CurrentTab, setCurrentTab] = useState("summary");
  const [orders, setOrders] = useState();
  const [allOrders, setAllOrders] = useState();
  const [analytic, setAnalytics] = useState();
  const [analyticUpdated, setAnalyticUpdated] = useState(false);
  const [pagination, setPagination] = useState({
    recordsPerPage: 0,
    totalRecords: 0,
    currentPage: 0,
  });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const setOrderStatus = (currTab) => {
    switch (currTab) {
      case "sent":
        return "CREATED";
      case "accepted":
        return "ACCEPTED";
      case "fulfilled":
        return "FULFILLED";
      case "partial_fulfilled":
        return "PARTIALLY_FULFILLED";
      case "shipped":
        return "SHIPPED";
      case "partially_shipped":
        return "PARTIALLY_SHIPPED";
      case "rejected":
        return "REJECTED";
      case "cancelled":
        return "CANCELLED";
      case "transfer":
        return null;
      default:
        return null;
    }
  };

  const isTransfer =
    CurrentTab === "summary" || CurrentTab === "cancelled"
      ? null
      : CurrentTab === "transfer"
      ? true
      : false;

  useEffect(() => {
    setPage(0);
  }, [CurrentTab]);

  useEffect(() => {
    const fetchData = async () => {
      const outboundAnalytics = await getOutboundOrdersAnalytics();
      const outbound = await getOutboundOrders(
        setOrderStatus(CurrentTab),
        isTransfer,
        page,
        limit,
      );
      setAnalytics(outboundAnalytics?.data);
      setOrders(outbound?.data?.data);
      setPagination({
        recordsPerPage: outbound?.data?.limit,
        totalRecords: outbound?.data?.totalRecords,
        currentPage: outbound?.data?.page,
      });
      setAllOrders(outbound?.data?.data);
    };
    setTab(setOrderStatus(CurrentTab));
    setTransfer(isTransfer);
    fetchData();
  }, [analyticUpdated, CurrentTab, page, limit, isTransfer]);

  useEffect(() => {
    SearchList(searchCapture);
  }, [searchCapture]);

  const SearchList = (keyword) => {
    if (!keyword) {
      setOrders(allOrders);
      return;
    }

    const fuse = new Fuse(allOrders, {
      keys: ["id"],
    });

    const result = fuse.search(keyword);
    const searchResult = [];
    if (result.length) {
      result.forEach((item) => {
        searchResult.push(item.item);
      });
      setOrders(searchResult);
    } else {
      setOrders([]);
    }
  };

  const myref = useRef();
  const [scrollClick, setScrollClicked] = useState("left-end");

  const scrollRight = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
    setScrollClicked(true);
  };

  const scrollLeft = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
  };

  return (
    <React.Fragment>
      <div className={`Analytic__wrapper`}>
        <div className='Scroll__Analytic_list' ref={myref}>
          <AnalyticCard
            state='summary'
            icon={Summary}
            title={`${t("summary")}`}
            number={"-"}
            tab={CurrentTab}
            setTab={setCurrentTab}
            // label="10%"
            labelType='pink_label'
          />
          <AnalyticCard
            state='sent'
            icon={OrdersSent}
            title={`${t("sent")}`}
            number={formatNumber(analytic?.sentOrders)}
            tab={CurrentTab}
            setTab={setCurrentTab}
            // label="50%"
            labelType='green_label'
          />
          <AnalyticCard
            state='accepted'
            icon={OrdersAccepted}
            title={` ${t("accepted")}`}
            number={formatNumber(analytic?.acceptedOrders)}
            tab={CurrentTab}
            setTab={setCurrentTab}
            // label="20%"
            labelType='blue_label'
          />

          <AnalyticCard
            state='fulfilled'
            icon={OrdersFulfilled}
            title={`${t("fulfilled")}`}
            number={formatNumber(analytic?.fullFiledOrders)}
            tab={CurrentTab}
            setTab={setCurrentTab}
            // label="50%"
            labelType='green_label'
          />

          <AnalyticCard
            state='partial_fulfilled'
            icon={PartiallyFulfilled}
            title={`${t("partially_fulfilled")}`}
            number={formatNumber(analytic?.partialFullFiledOrders)}
            tab={CurrentTab}
            setTab={setCurrentTab}
            // label="50%"
            labelType='green_label'
          />

          <AnalyticCard
            state='shipped'
            icon={Shipped}
            title={`${t("shipped")}`}
            number={formatNumber(analytic?.shippedOrders)}
            tab={CurrentTab}
            setTab={setCurrentTab}
            // label="50%"
            labelType='green_label'
          />

          <AnalyticCard
            state='partially_shipped'
            icon={PartiallyShipped}
            title={`${t("partially_shipped")}`}
            number={formatNumber(analytic?.partiallyShippedOrders)}
            tab={CurrentTab}
            setTab={setCurrentTab}
            // label="50%"
            labelType='green_label'
          />

          <AnalyticCard
            state='cancelled'
            icon={OrdersCanceled}
            title={`${t("cancelled")}`}
            number={formatNumber(analytic?.canceledOrders)}
            tab={CurrentTab}
            setTab={setCurrentTab}
          />

          <AnalyticCard
            state='rejected'
            icon={OrdersRejected}
            title={`${t("rejected")}`}
            number={formatNumber(analytic?.rejectedOrders)}
            tab={CurrentTab}
            setTab={setCurrentTab}
          />

          {orgLevel !== "OUTREACH_CENTRE" && (
            <AnalyticCard
              state='transfer'
              icon={OrdersTransfers}
              title={`${t("transfer")}`}
              number={formatNumber(analytic?.transferOrders)}
              tab={CurrentTab}
              setTab={setCurrentTab}
              labelType='blue_label'
            />
          )}
        </div>
        {scrollClick === "right-end" && (
          <div
            className='scroll__analytic_btn left_btn'
            onClick={() => {
              scrollLeft(-1024);
              setScrollClicked("left-end");
            }}
          >
            <i className='fa-solid fa-chevron-left'></i>
          </div>
        )}

        {scrollClick === "left-end" && (
          <div
            className='scroll__analytic_btn right_btn'
            onClick={() => {
              scrollRight(1024);
              setScrollClicked("right-end");
            }}
          >
            <i className='fa-solid fa-chevron-right'></i>
          </div>
        )}
      </div>

      <div className='Page__table_space'>
        <OrderOutTable
          orders={orders}
          CurrentTab={CurrentTab}
          setCurrentTab={setCurrentTab}
          filteredColumns={filteredColumns}
          setAnalyticUpdated={setAnalyticUpdated}
          pagination={pagination}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      </div>
    </React.Fragment>
  );
}
