import React from 'react';
import {
  BarChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
} from 'recharts';
import EmptyTable from '../../../../../shared/utils/emptyTable/EmptyTable';
import GraphEmpty from '../../../../../shared/utils/emptyTable/GraphEmpty';

// Reusable component definition
export default function BarGraph({ data, xAxisKey, bars, title, height }) {
  return data?.length > 0 ? (
    <div className='chart_container'>
      <div className='chart_headline'>
        <h1 className='page__body_fs mi_bold'>{title}</h1>
      </div>
      <ResponsiveContainer width='100%' height={height}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey={xAxisKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          {bars.map((bar, index) => (
            <Bar key={index} dataKey={bar.dataKey} fill={bar.fill} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div className='chart_container'>
      <div className='chart_headline'>
        <h1 className='page__body_fs mi_bold'>{title}</h1>
      </div>

      <GraphEmpty />
    </div>
  );
}
