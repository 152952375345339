export const buildParams = (data, page, limit) => {
  const params = {
    locations: JSON.stringify(data.locations),
    page: page + 1,
    limit: limit,
  };

  if (data.fromDate) {
    params.fromDate = data.fromDate;
  }

  if (data.toDate) {
    params.toDate = data.toDate;
  }

  return params;
};

export const buildParamsWithPagination = (data, status, isTransfer) => {
  const params = {
    locationIds: data.locations,
  };

  if (data.fromDate) {
    params.fromDate = data.fromDate;
  }

  if (data.toDate) {
    params.toDate = data.toDate;
  }

  if (status) {
    params.status = status;
  }
  if (isTransfer) {
    params.isTransfer = isTransfer;
  }

  return params;
};

export function formatBDPhoneNumber(phoneNumber) {
  const cleaned = phoneNumber?.replace(/\D/g, '');

  if (cleaned.length === 13 && cleaned.startsWith('880')) {
    return `+${cleaned.slice(0, 3)} ${cleaned.slice(3, 5)} ${cleaned.slice(
      5,
      9
    )} ${cleaned.slice(9)}`;
  }

  return phoneNumber;
}
