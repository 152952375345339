import React from "react";
import { doseUtilizedData, stockAvailabilityData, stockOutData } from "../data";
import StockGraph from "./InvGraphs/LineGraph";
import ConfigureTable from "../../../../shared/configureTable/ConfigureTable";
import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import BarGraph from "./InvGraphs/BarGraph";
import { useSearchParams } from "react-router-dom";
import { useGetBeneficiariesGraph } from "../../../../services/lastmile-services/queries";
import { stringToColorHex } from "../../../../shared/utils/utils";

export default function DataLastMileGraph({ handlePrint }) {
  // States
  const [view, setView] = React.useState("grid");
  const [graphType, setGraphType] = React.useState("Bars");
  const [graphHeight, setGraphHeight] = React.useState(250);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const columns = [
    { key: "doseUtilized", title: "Dose Utilized" },
    { key: "noOfBeneficiaries", title: "No of Beneficiaries" },
  ];

  const initialColumns = {
    doseUtilized: true,
    noOfBeneficiaries: true,
  };

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.key],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get("locations").split(","),
    fromDate: searchParams.get("fromDate") || null,
    toDate: searchParams.get("toDate") || null,
  };

  const { data, isLoading, isError } = useGetBeneficiariesGraph(apiBody);

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error: {isError.message}</div>;

  // Extract dynamic location keys from API data
  const locationKeys =
    data.length > 0 ? Object.keys(data[0]).filter((key) => key !== "date") : [];

  // Mapping API data to graph structure with dynamic keys
  const mappedData = data.map((item) => {
    const mappedItem = { date: item.date };
    locationKeys.forEach((key) => {
      mappedItem[key] = item[key];
    });
    return mappedItem;
  });

  const graphs = [
    {
      key: "doseUtilized",
      data: [],
      xAxisKey: "date",
      title: "Dose Utilized Over Time",
      bars: locationKeys.map((key) => ({
        dataKey: key,
        fill: stringToColorHex(key),
      })),
      lines: locationKeys.map((key) => ({
        dataKey: key,
        stroke: stringToColorHex(key),
      })),
    },
    {
      key: "noOfBeneficiaries",
      data: mappedData,
      xAxisKey: "date",
      title: "No. of Beneficiaries Over Time",
      bars: locationKeys.map((key) => ({
        dataKey: key,
        fill: stringToColorHex(key),
      })),
      lines: locationKeys.map((key) => ({
        dataKey: key,
        stroke: stringToColorHex(key),
      })),
    },
  ];

  return (
    <div>
      <div className='Data_graph_header printing_graph_actions'>
        <div className='graph__left'>
          <div className='graph_column'>
            <span>Graph Filters:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              <span>Default: Show all</span>
            </button>
            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
              title={"Filter the Graph"}
            />
          </div>
          <div className='graph_column'>
            <span>Graph Exports:</span>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handlePrint}
            >
              <span>Download as PDF</span>
            </button>
          </div>
        </div>
        {/* <h1 className='graph_title'>Indents</h1> */}
        <div className='graph__actions'>
          <div className='graph_column'>
            <span>Graph View:</span>
            <div className='graph_view_option'>
              <Tooltip title={"Full View"} arrow>
                <div
                  className={`graph_view ${view === "full" && "active"}`}
                  onClick={() => setView("full")}
                >
                  <i className='bx bxs-bar-chart-square'></i>
                </div>
              </Tooltip>
              <Tooltip title={"Grid View"} arrow>
                <div
                  className={`graph_view ${view === "grid" && "active"}`}
                  onClick={() => setView("grid")}
                >
                  <i className='bx bxs-grid-alt'></i>
                </div>
              </Tooltip>
            </div>
          </div>

          <div className='graph_column graph_type'>
            <span>Graph Type:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphType(e.target.value);
                }}
                value={graphType}
              >
                <MenuItem value={"Bars"}>Bar Graph</MenuItem>
                <MenuItem value={"Lines"}>Line Graph</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='graph_column graph_type'>
            <span>Graph Height:</span>
            <FormControl fullWidth>
              <Select
                size='small'
                className='mui_custom_input_field'
                id='level'
                name='level'
                onChange={(e) => {
                  setGraphHeight(e.target.value);
                }}
                value={graphHeight}
              >
                <MenuItem value={250}>Normal</MenuItem>
                <MenuItem value={400}>Large</MenuItem>
                <MenuItem value={500}>Extra</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className={`Graph_list ${view}`}>
        {graphs.map((graph) =>
          filteredColumns.some((col) => col.key === graph.key) ? (
            graphType === "Bars" ? (
              <BarGraph
                key={graph.key}
                data={graph.data}
                xAxisKey={graph.xAxisKey}
                title={graph.title}
                bars={graph.bars}
                height={graphHeight}
              />
            ) : (
              <StockGraph
                key={graph.key}
                data={graph.data}
                xAxisKey={graph.xAxisKey}
                title={graph.title}
                lines={graph.lines}
                height={graphHeight}
              />
            )
          ) : null,
        )}
      </div>
    </div>
  );
}
