import React from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28EFF'];

// Reusable component definition
export default function PieGraph({ data, title, height }) {
  return data?.length > 0 ? (
    <div className='chart_container'>
      <div className='chart_headline'>
        <h1 className='page__body_fs mi_bold'>{title}</h1>
      </div>
      <ResponsiveContainer width='100%' height={height}>
        <PieChart>
          <Pie
            data={data}
            cx='50%'
            cy='50%'
            outerRadius={80}
            fill='#8884d8'
            dataKey='count'
            nameKey={'_id'}
            label
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div className='chart_container'>
      <div className='chart_headline'>
        <h1 className='page__body_fs mi_bold'>{title}</h1>
      </div>

      <div>No Data Found</div>
    </div>
  );
}
