import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../shared/utils/breadCrumb/BreadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  createMaintenanceJob,
  getEquipmentList,
  getUserLocations,
  updateMaintenanceJob,
} from "../../../../redux/inventoryActions";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ColdChain1 from "/icons/all/Cold Chain.svg";

export default function AddJobCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const viewStatus = searchParams.get("view");

  const params = useLocation();

  const UpdateJobValues = params?.state?.value;

  const [date, time] = UpdateJobValues?.date
    ? UpdateJobValues?.date.split("T")
    : "";

  const initialValues = {
    date: date || dayjs().format("YYYY-MM-DD"),
    equipmentType: UpdateJobValues?.assetDetails?._id || "",
    location: UpdateJobValues?.locationDetails?._id || "",
    modalNumber: UpdateJobValues?.assetDetails?.model || "",
    serialNumber: UpdateJobValues?.assetDetails?.serialNumber || "",
    status: UpdateJobValues?.status || "COMPLETED",
    maintenance: UpdateJobValues?.type || "PREEMPTIVE",
    reportedFault: UpdateJobValues?.fault || "",
    sparePartsUsed: UpdateJobValues?.spareParts || "",
    reportedFaultComments: UpdateJobValues?.comments || "",
    maintenanceServicesCompleted: UpdateJobValues?.summary || "",
    otherComments: UpdateJobValues?.otherComments || "",
    repairedBy: UpdateJobValues?.repairedBy?.name || "",
    repairedByDesignation: UpdateJobValues?.repairedBy?.designation || "",
    repairedByDateAndSignature:
      UpdateJobValues?.repairedBy?.signature || dayjs().format("YYYY-MM-DD"),
    districtStaffName: UpdateJobValues?.staff?.name || "",
    staffDesignation: UpdateJobValues?.staff?.designation || "",
    staffDateAndSignature:
      UpdateJobValues?.staff?.signature || dayjs().format("YYYY-MM-DD"),
  };

  const validationSchema = Yup.object().shape({
    date: Yup.date().required(t("date_error")),
    equipmentType: Yup.string().required(t("equipment_type_error")),
    modalNumber: Yup.string().required(t("model_number_error")),
    serialNumber: Yup.string().required(t("serial_number_error")),
    status: Yup.string().required(t("status_error")),
    maintenance: Yup.string().required(t("maintenance_error")),
    reportedFault: Yup.string().required(t("reported_fault_error")),
    sparePartsUsed: Yup.string().required(t("spare_parts_used_error")),
    reportedFaultComments: Yup.string().required(
      t("reported_fault_comments_error"),
    ),
    maintenanceServicesCompleted: Yup.string().required(
      t("maintenance_services_completed_error"),
    ),
    otherComments: Yup.string(),
    repairedBy: Yup.string().required(t("repaired_installed_by_error")),
    repairedByDesignation: Yup.string().required(
      t("repaired_by_designation_error"),
    ),
    repairedByDateAndSignature: Yup.string().required(
      t("repaired_by_date_signature_error"),
    ),
    districtStaffName: Yup.string().required(t("district_staff_name_error")),
    staffDesignation: Yup.string().required(
      t("district_staff_designation_error"),
    ),
    staffDateAndSignature: Yup.string().required(
      t("district_staff_date_signature_error"),
    ),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const apiBody = {
      date: values.date,
      assetId: values.equipmentType,
      locationId: locationData?.[0]?._id,
      modalNumber: values.modalNumber,
      serialNumber: values.serialNumber,
      status: values.status,
      type: values.maintenance,
      fault: values.reportedFault,
      spareParts: values.sparePartsUsed,
      comments: values.reportedFaultComments,
      summary: values.maintenanceServicesCompleted,
      otherComments: values.otherComments,

      repairedBy: {
        name: values.repairedBy,
        designation: values.repairedByDesignation,
        timestamp: values.repairedByDateAndSignature,
      },
      staff: {
        name: values.districtStaffName,
        designation: values.staffDesignation,
        timestamp: values.staffDateAndSignature,
      },
    };

    if (viewStatus === "new") {
      try {
        const res = await createMaintenanceJob(apiBody);
        if (res.success === true) {
          navigate("/user/maintenance");
          toast.success(t("job_created_msg"));
          setSubmitting(false);
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (viewStatus === "update") {
      try {
        const res = await updateMaintenanceJob(UpdateJobValues?._id, apiBody);
        if (res.success === true) {
          navigate("/user/maintenance");
          toast.success(t("job_updated_msg"));
          setSubmitting(false);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const [equipmentData, setEquipmentData] = useState();
  const [locationData, setLocationData] = useState();

  const [selectedEquipment, setSelectedEquipment] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getEquipmentList();
      setEquipmentData(res.data.data);
      const resLoc = await getUserLocations();
      setLocationData(resLoc.data.locations);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectedEquipment = () => {
      const equip = equipmentData?.find(
        (item) => item._id === formik.values.equipmentType,
      );
      formik.setFieldValue("modalNumber", equip?.model);
      formik.setFieldValue("serialNumber", equip?.serialNumber);
      setSelectedEquipment(equip);
    };
    fetchSelectedEquipment();
  }, [formik.values.equipmentType]);

  useEffect(() => {
    formik.setFieldValue("status", "PENDING");
    formik.setFieldValue("maintenance", "PREVENTIVE");
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <img src={ColdChain1} className='Campaign__icon' />
            <h1 className='Page__headline_title_fs'>
              {viewStatus === "new"
                ? t("new")
                : t("view") + " / " + t("update")}{" "}
              {t("maintenance_job_card")}
            </h1>
          </div>
          <BreadCrumb
            url1='/user/maintenance'
            Link1={t("maintenance")}
            Link2={`${
              viewStatus === "new" ? t("new") : t("view") + " / " + t("update")
            } ${t("maintenance_job")}`}
          />
        </div>
        <div className='FunctionalData__form'>
          <div className='AddJobCard__card'>
            <div className='AddJobCard__header'>
              <h1 className='FunctionalData_heading_fs'>
                {t("equipment_information")}
              </h1>
            </div>
            <div className='AddJobCard__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>{t("date")}</p>
                  <input
                    type='date'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("date")}
                  />
                  {formik.errors.date && formik.touched.date && (
                    <div className='Input__error_fs'>{formik.errors.date}</div>
                  )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("asset")} <b>*</b>{" "}
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_field'
                      {...formik.getFieldProps("equipmentType")}
                    >
                      {equipmentData?.map((item, index) => (
                        <MenuItem key={index} value={item._id}>
                          {item.id} - {item.model}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.equipmentType &&
                    formik.touched.equipmentType && (
                      <div className='Input__error_fs'>
                        {formik.errors.equipmentType}
                      </div>
                    )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>{t("model_number")}</p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("modalNumber")}
                  />
                  {formik.errors.modalNumber && formik.touched.modalNumber && (
                    <div className='Input__error_fs'>
                      {formik.errors.modalNumber}
                    </div>
                  )}
                </article>
              </div>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>{t("serial_number")}</p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("serialNumber")}
                  />
                  {formik.errors.serialNumber &&
                    formik.touched.serialNumber && (
                      <div className='Input__error_fs'>
                        {formik.errors.serialNumber}
                      </div>
                    )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("status")}
                    <b>*</b>
                  </p>
                  <FormControl>
                    <RadioGroup
                      row
                      name='status'
                      {...formik.getFieldProps("status")}
                    >
                      <FormControlLabel
                        value='PENDING'
                        control={<Radio />}
                        label='Pending'
                      />
                      <FormControlLabel
                        value='COMPLETED'
                        control={<Radio />}
                        label='Completed'
                      />
                    </RadioGroup>
                    {formik.errors.status && formik.touched.status && (
                      <div className='Input__error_fs'>
                        {formik.errors.status}
                      </div>
                    )}
                  </FormControl>
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("maintenance")}
                    <b>*</b>
                  </p>
                  <FormControl>
                    <RadioGroup
                      row
                      name='maintenance'
                      {...formik.getFieldProps("maintenance")}
                    >
                      <FormControlLabel
                        value='PREVENTIVE'
                        control={<Radio />}
                        label='Preventive'
                      />
                      <FormControlLabel
                        value='CORRECTIVE'
                        control={<Radio />}
                        label='Corrective'
                      />
                    </RadioGroup>
                    {formik.errors.maintenance &&
                      formik.touched.maintenance && (
                        <div className='Input__error_fs'>
                          {formik.errors.maintenance}
                        </div>
                      )}
                  </FormControl>
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='AddJobCard__card'>
            <div className='AddJobCard__header'>
              <h1 className='FunctionalData_heading_fs'>
                {t("reported_fault_information")}
              </h1>
            </div>
            <div className='AddJobCard__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("reported_fault")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("reportedFault")}
                  />

                  {formik.errors.reportedFault &&
                    formik.touched.reportedFault && (
                      <div className='Input__error_fs'>
                        {formik.errors.reportedFault}
                      </div>
                    )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("spare_parts_used")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("sparePartsUsed")}
                  />

                  {formik.errors.sparePartsUsed &&
                    formik.touched.sparePartsUsed && (
                      <div className='Input__error_fs'>
                        {formik.errors.sparePartsUsed}
                      </div>
                    )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("reported_fault_comments")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("reportedFaultComments")}
                  />
                  {formik.errors.reportedFaultComments &&
                    formik.touched.reportedFaultComments && (
                      <div className='Input__error_fs'>
                        {formik.errors.reportedFaultComments}
                      </div>
                    )}
                </article>
              </div>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("summary_of_maintenance_services_completed")}
                    <b>*</b>
                  </p>
                  <textarea
                    className='mi_custom_textarea'
                    {...formik.getFieldProps("maintenanceServicesCompleted")}
                  />
                  {formik.errors.maintenanceServicesCompleted &&
                    formik.touched.maintenanceServicesCompleted && (
                      <div className='Input__error_fs'>
                        {formik.errors.maintenanceServicesCompleted}
                      </div>
                    )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("if_any_other_comments")}
                  </p>
                  <textarea
                    className='mi_custom_textarea'
                    {...formik.getFieldProps("otherComments")}
                  />
                  {formik.errors.otherComments &&
                    formik.touched.otherComments && (
                      <div className='Input__error_fs'>
                        {formik.errors.otherComments}
                      </div>
                    )}
                </article>
                <article></article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='AddJobCard__card'>
            <div className='AddJobCard__header'>
              <h1 className='FunctionalData_heading_fs'>
                {t("staff_information")}
              </h1>
            </div>
            <div className='AddJobCard__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("repaired_installed_by")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("repairedBy")}
                  />
                  {formik.errors.repairedBy && formik.touched.repairedBy && (
                    <div className='Input__error_fs'>
                      {formik.errors.repairedBy}
                    </div>
                  )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("reported_by_designation")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("repairedByDesignation")}
                  />

                  {formik.errors.repairedByDesignation &&
                    formik.touched.repairedByDesignation && (
                      <div className='Input__error_fs'>
                        {formik.errors.repairedByDesignation}
                      </div>
                    )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("reported_date_signature")}
                    <b>*</b>
                  </p>
                  <input
                    type='date'
                    className='mi_custom_textfield date_field'
                    {...formik.getFieldProps("repairedByDateAndSignature")}
                  />
                  {formik.errors.repairedByDateAndSignature &&
                    formik.touched.repairedByDateAndSignature && (
                      <div className='Input__error_fs'>
                        {formik.errors.repairedByDateAndSignature}
                      </div>
                    )}
                </article>
              </div>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("dist_staff_name")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("districtStaffName")}
                  />
                  {formik.errors.districtStaffName &&
                    formik.touched.districtStaffName && (
                      <div className='Input__error_fs'>
                        {formik.errors.districtStaffName}
                      </div>
                    )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("dist_staff_designation")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("staffDesignation")}
                  />

                  {formik.errors.staffDesignation &&
                    formik.touched.staffDesignation && (
                      <div className='Input__error_fs'>
                        {formik.errors.staffDesignation}
                      </div>
                    )}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("dist_staff_date_signature")}
                    <b>*</b>
                  </p>
                  <input
                    type='date'
                    className='mi_custom_textfield date_field'
                    {...formik.getFieldProps("staffDateAndSignature")}
                  />
                  {formik.errors.staffDateAndSignature &&
                    formik.touched.staffDateAndSignature && (
                      <div className='Input__error_fs'>
                        {formik.errors.staffDateAndSignature}
                      </div>
                    )}
                </article>
              </div>
            </div>
          </div>
        </div>
        {/* Additional form sections go here */}
        <div className='FunctionalData__actions'>
          <button type='submit' className='mi_btn mi_btn_medium mi_btn_primary'>
            <span> {viewStatus === "new" ? t("submit") : t("update")}</span>
          </button>
        </div>
      </section>
    </form>
  );
}
